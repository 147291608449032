<template>
    <NavMenu
        :hide="hide"
        v-if="$store.state.user.isLoggedIn"
        v-model="show"
        :main-menu="mainMenu"
        @menu="$emit('menu')"
    >
        <v-list class="py-0">
            <v-list-item class="py-2">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-bold mb-1">
                        <v-progress-circular
                            v-if="partnerLoading"
                            class="mr-1"
                            :width="3"
                            :size="20"
                            color="primary"
                            indeterminate
                        />
                        <span v-html="partnerData ? partnerData.companyname : 'Loading...'" />
                    </v-list-item-title>
                    <v-list-item-subtitle
                        class="text--primary"
                        v-if="partnerData && partnerData.refnum"
                    >
                        Dealer code {{ partnerData.refnum }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle> Partner </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item
                link
                :to="{ name: $store.state.user.isAdmin ? 'AdminPartnerSummary' : 'PartnerHome' }"
            >
                <v-list-item-action>
                    <v-icon v-html="$store.state.user.isAdmin ? $vuetify.icons.values.partner : 'mdi-home'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title
                        ><span
                            v-html="$store.state.user.isAdmin ? 'Summary' : 'Home'"
                    /></v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-divider />

          <v-list-item
                link
                :to="{ name: $store.state.user.isAdmin ? 'AdminPartnerCustomers' : 'PartnerCustomers' }"
            >
                <v-list-item-action>
                    <v-icon v-html="'mdi-account-multiple'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Customers</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip small pill style="cursor: pointer;">{{partnerData.customer_count || '-'}}</v-chip>
                </v-list-item-action>
            </v-list-item>


          <v-list-item v-if="$store.state.user.isAdmin" link :to="{ name: $store.state.user.isAdmin ? 'AdminPartnerContacts' : 'PartnerContacts' }">
            <v-list-item-action>
              <v-icon v-html="mdiCardAccountDetails" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="partnerData.contact_count > 0">
              <v-chip small pill style="cursor: pointer;">{{partnerData.contact_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item
              disabled
              v-if="$store.state.user.isAdmin"
            >
            <v-list-item-action>
              <v-icon v-html="'mdi-account'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item
                    link
                    :to="{ name: $store.state.user.isAdmin ? 'AdminPartnerCommissions' : 'PartnerCommissions' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-currency-usd</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Commissions</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-divider />


          <v-list-item
                link
                v-if="$store.state.user.isAdmin"
                :to="{ name: 'AdminPartnerHistory' }"
            >
                <v-list-item-action>
                    <v-icon v-html="'mdi-history'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>History</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-list-item
              link
              :to=" $store.state.user.isAdmin ? {name: 'AdminPartnerNBNSearch', params:{partnerId:this.partnerId}} :  {name: 'PartnerNBNSearch'}"
          >
            <v-list-item-action>
              <v-icon v-html="'mdi-web'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>NBN</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;"
                      color="orange"
                      text-color="white"
              >New</v-chip>
            </v-list-item-action>
          </v-list-item>

        </v-list>
        <template v-slot:bottom v-if="!partnerLoading && $store.state.user.portaone_url">
            <v-list-item tag="a" :href="$store.state.user.portaone_url + 'representative/' + $route.params.partnerId" target="_blank">
                <v-list-item-action>
                    <v-icon>mdi-open-in-new</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Open in Porta Admin</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </NavMenu>
</template>

<script>
import partnerDataMixin from '../../../mixins/partnerDataMixin';
import NavMenu from '../templates/NavMenu';
import { mdiPhoneVoip, mdiNumeric, mdiCardAccountDetails } from '@mdi/js';

export default {
    name: 'PartnerMenu',
    components: { NavMenu },
    mixins: [partnerDataMixin],
    data: () => ({
        show: false,
        mdiPhoneVoip: mdiPhoneVoip,
        mdiNumeric: mdiNumeric,
        mdiCardAccountDetails: mdiCardAccountDetails,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        mainMenu: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show: function (val) {
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    mounted() {
        this.show = this.value;
    },
};
</script>
