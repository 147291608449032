<template>
    <v-theme-provider dark>

        <v-app-bar
            app
            clipped-left
            clipped-right
            color="breeze_blue"
            :class="$vuetify.breakpoint.mdAndUp ? 'elevation-2' : 'elevation-0'"

        >
            <!-- Navigation Menu Button -->
            <v-expand-x-transition>
                <v-app-bar-nav-icon
                    class="mr-1"
                    v-show="menuButton"
                    @click="$emit('menu')"
                />
            </v-expand-x-transition>

            <!-- Breeze App Logo -->


            <div class="d-flex align-center flex-grow-1" >
                <div v-if="!showSearch || $vuetify.breakpoint.smAndUp">
                    <BreezeLogo
                        :max-height="maxHeight"
                        :max-width="maxWidth"
                        white
                        class="mb-1 mb-1 mr-2 mr-sm-4"
                    />
                    <v-chip
                            v-if="badgeText"
                            class="mr-sm-4"
                            color="breeze_orange"
                            v-show="!showSearch || $vuetify.breakpoint.smAndUp"
                            :small="$vuetify.breakpoint.smAndDown"
                            label
                            v-text="badgeText"
                    />
                </div>

                <div class="flex-grow-1" v-if="!showSearch || $vuetify.breakpoint.smAndUp" />

                <!-- quick search -->
                <div class="flex-grow-1" :style="$vuetify.breakpoint.smAndUp ? 'max-width: 350px;' : undefined">
                    <QuickSearch
                        :visible="showSearch || $vuetify.breakpoint.smAndUp"
                        :autofocus="$vuetify.breakpoint.xsOnly"
                        @close="showSearch=false"
                    />
                </div>
                <div class="hidden-sm-and-up">
                    <ButtonWithTooltip
                        :icon="showSearch ? 'mdi-close' : 'mdi-magnify'"
                        @click="showSearch = !showSearch"
                        :tooltip="showSearch ? 'Close quick search' : 'Quick search'"
                    />
                </div>

                <!-- theme switcher button -->
                <div class="hidden-xs-only ml-1 ml-sm-2">
                    <ThemeSwitcher />
                </div>
                <!-- ThemeSwitcher v-show="$vuetify.breakpoint.smAndUp" / -->

                <!-- user menu -->
                <v-expand-x-transition>
                    <div class="ml-1 ml-sm-4" v-show="!showSearch || $vuetify.breakpoint.smAndUp">
                        <UserMenu />
                    </div>
                </v-expand-x-transition>

            </div>

        </v-app-bar>
    </v-theme-provider>
</template>

<script>
import QuickSearch from '@/components/core/QuickSearch';
import UserMenu from '@/components/core/UserMenu';
import ThemeSwitcher from '../pieces/ThemeSwitcher';
import ButtonWithTooltip from '../pieces/ButtonWithTooltip';
import BreezeLogo from '../pieces/Public/BreezeLogo';
export default {
    name: 'ApplicationBar',
    components: { BreezeLogo, ButtonWithTooltip, ThemeSwitcher, UserMenu, QuickSearch },
    data: () => ({
        showSearch: false,
    }),
    computed: {
        maxHeight: function () {
            return this.$vuetify.breakpoint.mdAndUp ? 32 : 28;
        },
        maxWidth: function () {
            return this.$vuetify.breakpoint.mdAndUp ? 180 : 150;
        },
        badgeText: function () {
            return process.env.VUE_APP_BETA === 'beta' ? window.location.host.split('.')[0].split(':')[0].toUpperCase() : null;
        },
    },
    props: {
        menuButton: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
